// FAQ block

.block {
    &--faq {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.faq {
    $self: &;

    @include make-row;
    margin: 0;

    &__title,
    &__content,
    &__buttons {
        @include make-col(12);
    }

    &__sidebar {
        @include make-col(2);
        padding: 0 15px 0 0;
        text-align: right;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0;
            text-align: left;
        }

        + #{ $self }__content {
            @include make-col(10);
            padding: 0 0 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(12);
                padding: 0;
            }
        }
    }
}

.faq-categories-overview {
    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(6);
        padding: 0 15px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.faq-overview {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-margin - 20px);

    &__item {
        margin: 0 0 20px;
    }
}

.faq-question {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1px;
    overflow: hidden;
    margin: 0 -1px;
    border-bottom: 1px solid $color-border;

    &__title {
        position: relative;
        display: flex;
        margin: 0;
        padding: 0 70px 0 0;
        cursor: pointer;
        min-height: 50px;

        &::after {
            @include icon(arrow, $color-blue-dark, 16px, 12px);
            position: absolute;
            top: -5px;
            right: 0;
            display: block;
            width: 40px;
            height: 40px;
            content: '';
            transform: rotate(90deg);
            transition: all .3s;

            @include media-breakpoint-down(md) {
                right: 0;
            }
        }

        &:focus-visible {
            outline: 1px solid $color-burdau;
        }
    }

    &__content {
        display: none;
        flex: 1;
        padding: 0;

        .richtext {
            margin: 0 0 20px;

            + .read-more {
                margin-top: -20px;
            }
        }

        .read-more {
            margin: 0 0 20px;
        }
    }

    &.active {
        #{ $self } {
            &__title {
                &::after {
                    transform: rotate(-90deg);
                }
            }

            &__content {
                display: block;
            }
        }
    }
}
