.navigation {
    $self: &;

    ul {
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $text-color;
                padding: 3px 5px;

                @include media-breakpoint-down(lg) {
                    padding: 6px 0;
                }
            }

            > ul {
                display: none;
            }


            &:hover,
            &:focus {
                > a {
                    color: $color-blue-dark;
                    text-decoration: none;
                }
            }

            &.active {
                > a {
                    color: $color-blue-dark;
                }

                > ul {
                    display: block;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $text-color, 15px, 14px);
                    position: absolute;
                    top: 8px;
                    right: 0;
                    display: none;
                    width: 26px;
                    height: 26px;
                    padding: 0;
                    transform: rotate(90deg);
                    transition: all .3s;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }
                }

                &:hover,
                &:focus {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-dark, 15px, 14px);
                    }
                }

                &.active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-dark, 15px, 14px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(-90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-dark, 14px, 14px);
                        transform: rotate(90deg);
                    }

                    > ul {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }

                // scss-lint:enable MergeableSelector
            }
        }
    }

    &--header {
        margin: 0 -10px 0 auto;

        @include media-breakpoint-down(lg) {
            position: fixed;
            top: 0;
            left: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            padding: 80px 0 83px;
            margin: 0;
            overflow: auto;
            background-color: $color-white;
            transition: all .3s ease-in-out;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            @include media-breakpoint-down(lg) {
                display: block;
                padding: 0 15px;
                text-align: left;
            }

            li {
                margin: 0 5px;

                @include media-breakpoint-down(xl) {
                    margin: 0;
                }

                @include media-breakpoint-down(lg) {
                    display: block;
                }

                a {
                    color: $color-gray;
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: $font-weight-bold;
                    text-transform: none;
                    position: relative;
                    text-decoration: none;

                    @include media-breakpoint-down(lg) {
                        font-size: 16px;
                        line-height: 32px;
                    }

                    &::after {
                        content: '';
                        width: 0;
                        height: 2px;
                        background: $color-burdau;
                        border-radius: 3px;
                        position: absolute;
                        left: 5px;
                        bottom: 0;
                        transition: width .3s ease-in-out;

                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }

                > ul {
                    position: absolute;
                    left: -99999px;
                    display: block;
                    width: 200px;
                    padding: 0 5px;
                    background-color: $color-white;

                    @include media-breakpoint-down(lg) {
                        position: static;
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0 0 0 20px;
                        background-color: transparent;
                    }

                    li {
                        display: block;
                        margin: 0;
                    }
                }

                .is-subnav {
                    @include media-breakpoint-up(lg) {
                        visibility: hidden;
                        opacity: 0;
                    }
                }

                &.active {
                    a {
                        &::after {
                            width: 40%;
                        }
                    }

                    ul li {
                        a {
                            &::after {
                                width: 0;
                            }
                        }
                    }
                }

                &:hover,
                &:focus-within {
                    .is-subnav {
                        visibility: visible;
                        opacity: 1;
                    }

                    > a {
                        color: $color-blue-dark;
                    }

                    > ul {
                        left: auto;
                    }

                    a {
                        &::after {
                            width: 40%;
                        }
                    }
                }

                &.has-subnav {
                    position: static;

                    @include media-breakpoint-down(lg) {
                        position: relative;
                    }

                    .navigation {
                        left: -99999px;

                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus-within {
                        .is-subnav {
                            visibility: visible;
                            opacity: 1;
                        }

                        > a {
                            position: relative;

                            &::before {
                                position: absolute;
                                top: 0;
                                display: block;
                                width: 100%;
                                height: 50px;
                                content: '';
                            }

                            &::after {
                                @include icon(arrow, $text-color, 10px, 10px);
                            }
                        }

                        ul li a {
                            &::after {
                                width: 0;
                            }
                        }

                        .navigation {
                            left: 0;
                            display: flex;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > a {
                            &::after {
                                @include icon(close, $text-color, 8px, 8px);
                            }
                        }

                        .navigation {
                            @include media-breakpoint-down(lg) {
                                display: block;
                            }
                        }
                    }

                    &.active.subnav-active {
                        .navigation {
                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                &.has-subnav {
                    > a {
                        padding-right: 5px;

                        @include media-breakpoint-down(lg) {
                            padding-right: 34px;
                        }
                    }

                    > .toggle-subnav {
                        display: none;

                        @include media-breakpoint-down(lg) {
                            display: block;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }

    &--footer {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $color-white;
                    font-size: 10px;
                    line-height: 26px;
                    font-weight: $font-weight-normal;
                    padding: 0;
                }

                &:hover,
                &:focus {
                    a {
                        color: $color-orange;
                    }
                }
            }
        }
    }

    &--mobile {
        margin: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        display: none;
        z-index: 999;
        background: $color-white;
        box-shadow: 0 0 10px rgba($color-black, .1);

        @include media-breakpoint-down(lg) {
            display: block;
        }

        @media print {
            display: none;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            width: 100%;
            justify-content: space-around;

            li {
                max-width: 25%;

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: $font-weight-bold;
                    line-height: normal;
                    color: $text-color;
                    text-align: center;
                    padding: 15px 0 11px;

                    &:hover,
                    &:focus {
                        color: $color-burdau;
                    }
                }

                .icon {
                    position: relative;
                    display: block;
                    width: 18px;
                    height: 18px;
                    opacity: .5;
                    margin: 0 auto 10px;

                    &.faq {
                        @include icon(faq, $color-blue-dark, 18px, 18px);
                    }

                    &.recipe {
                        @include icon(recipe, $color-blue-dark, 18px, 18px);
                    }

                    &.phone {
                        @include icon(phone, $color-blue-dark, 18px, 18px);
                    }

                    &.account {
                        @include icon(account, $color-blue-dark, 18px, 18px);
                    }

                    &.vacancy {
                        @include icon(vacancy, $color-blue-dark, 18px, 18px);
                    }

                    &.education {
                        @include icon(education, $color-blue-dark, 18px, 18px);
                    }

                    &.req {
                        @include icon(req, $color-blue-dark, 18px, 18px);
                    }
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(lg) {
                left: 0;
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(lg) {
                top: 135px;
            }
        }
    }
}
