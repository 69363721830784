.modal {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px 20px 40px;
    overflow: auto;
    visibility: hidden;
    background: rgba($color-blue-dark, .9);
    z-index: -1;

    &__wrapper {
        position: relative;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            max-width: 50vw;
        }

        .btn {
            &--close {
                margin-bottom: 10px;
                margin-left: auto;
                box-shadow: 0 3px 10px rgba($color-black, .2);
            }
        }

        .is-h1,
        .is-h3 {
            text-transform: uppercase;
            padding-right: 20%;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        margin: 0 auto;
        padding: 20px 20px 0;
        color: $text-color;
        background: $color-white;
        box-shadow: 0 0 10px rgba($color-black, .2);
        border-radius: 6px;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .btn:not(.btn--link) {
            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
            }
        }

        > [data-modal] {
            position: absolute;
            top: -38px;
            right: 0;
            color: $color-white;
            padding: 0 0 0 15px;
            border: 0;
            text-transform: lowercase;

            &::after {
                @include icon(close, $color-white, 10px, 10px);
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 10px;
                height: 10px;
                margin: -3px 0 0;
                content: '';
            }

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: underline;

                &::after {
                    @include icon(close, $color-white, 10px, 10px);
                }
            }
        }
    }
}

[data-modal-container] {
    .modal {
        visibility: visible;
        z-index: 9999;
    }
}

html {
    &.modal-active {
        overflow: hidden;
    }
}
