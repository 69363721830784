*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: $font-weight-normal;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: #fff;
}

body {
    display: flex;
    flex-direction: column;
}

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

::selection {
    background-color: $color-burdau;
    color: $color-white;
}

::selection {
    background-color: $color-burdau;
    color: $color-white;
}

.search-highlight {
    background-color: $color-blue-dark;
    color: $color-white;
}

.special {
    font-weight: $font-weight-normal;
}

.date {
    font-weight: $font-weight-normal;
    font-size: 15px;
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

a {
    &:focus-visible {
        outline: 1px solid $color-burdau;
    }
}

li {
    &:focus-visible {
        outline: 1px solid $color-burdau;
    }
}

a,
.link {
    color: $text-color;
    outline: none;
    text-decoration: none;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus {
        color: $color-burdau;
        outline: none;
        text-decoration: underline;
    }

    &:focus-visible {
        outline: 1px solid $color-burdau;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

.table-wrapper {
    margin: 0 0 $default-margin;
    overflow-x: auto;

    table {
        margin: 0;

        tr {
            th,
            td {
                @include media-breakpoint-down(lg) {
                    min-width: 200px;
                }

                @include media-breakpoint-down(sm) {
                    min-width: unset;
                }
            }
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;

    &.table {
        tr {
            th,
            td {
                padding: 0;
            }
        }
    }

    .separator {
        padding: 0 20px;
    }
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    height: auto;
    max-width: 100%;

    &[loading] {
        width: auto;
    }
}

.jobs-page {
    h1, h2, h3, h4, h5 {
        margin-bottom: 10px !important;
    }

    .content-block__title {
        h1, h2 {
            text-align: center !important;
        }
    }
}

.location-image {
    &--hidden {
        display: none;
    }
}

.footnote {
    font-size: 12px;
    line-height: 26px;
    font-style: italic;
    font-weight: $font-weight-normal;
    text-align: center;
}

.info {
    display: block;
    font-style: italic;
    margin: 0 0 $default-margin;
    color: $text-color;
}

p {
    &.small {
        font-size: 10px;
        line-height: normal;
    }
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 25px 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.is-video {
    position: relative;

    &::after {
        @include icon(play, $color-white, 60px, 60px);
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        content: '';
    }

    &:hover,
    &:focus {
        &::after {
            @include icon(play, $color-burdau, 60px, 60px);
        }
    }

    &--cta {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        color: $color-white;
        background: $color-blue-dark;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        border: 1px solid $text-color;
        border-radius: 20vh;
        padding: 16px 30px;
        align-self: baseline;

        &::after {
            display: none;
        }

        .icon {
            width: 20px;
            height: 20px;
            background-size: contain;
        }

        span:not(.icon) {
            margin-left: 10px;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            background: $color-orange;
            border-color: $color-orange;
            color: $color-white;

            .icon {
                &--play {
                    @include icon(play, $color-white, 20px, 20px);
                }
            }
        }
    }
}

.icon {
    $self: &;
    display: inline-block;
    height: 40px;
    width: 40px;

    &--check {
        @include icon(check, $color-burdau, 40px, 40px);
    }

    &--phone {
        @include icon(phone, $color-blue-dark, 18px, 17px);
    }

    &--play {
        @include icon(play, $color-white, 20px, 20px);
        width: 20px;
        height: 20px;
    }

    &--instagram {
        @include icon(instagram, $color-white, 18px, 18px);
    }

    &--linkedin {
        @include icon(linkedin, $color-white, 18px, 18px);
    }

    &--email {
        @include icon(email, $color-white, 18px, 18px);
    }

    &--account {
        @include icon(account, $color-burdau, 20px, 20px);
        width: 20px;
        height: 20px;
    }

    &--faq {
        @include icon(faq, $color-burdau, 16px, 17px);
        width: 20px;
        height: 20px;
    }

    &--recipe {
        @include icon(recipe, $color-burdau, 16px, 17px);
        width: 20px;
        height: 20px;
    }

    &--req {
        @include icon(req, $color-burdau, 22px, 22px);
        width: 22px;
        height: 22px;
    }

    &--education {
        @include icon(education, $color-burdau, 24px, 10px);
        width: 24px;
        height: 22px;
    }

    &--vacancy {
        @include icon(vacancy, $color-burdau, 19px, 19px);
        width: 20px;
        height: 20px;
    }
}

picture,
figure {
    display: block;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
    padding-left: 20px;
    padding-right: 20px;
}

// scss-lint:disable IdSelector
#skiptocontent a {
    position: absolute;
    top: -45px;
    left: 0;
    padding: 6px;
    color: $color-white;
    background: $color-burdau;
    border-bottom-right-radius: 8px;
    transition: top .1s ease-out;
    z-index: 1000;

    &:focus {
        top: 0;
        outline: none;
    }
}

// scss-lint:enable IdSelector


.hidden {
    display: none;
}
