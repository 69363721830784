// Cards block

.block {
    &--cards {
        margin-bottom: $default-block-margin - $default-margin;
    }
}

.cards {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }
}

.card {
    position: relative;
    margin-bottom: $default-margin;

    &__image {
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 0 15px rgba($color-black, .1);
    }

    &__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
        width: 100%;
        padding: 20px 18%;
        left: 0;
        right: 0;
        top: 34px;

        @include media-breakpoint-down(lg) {
            padding: 10%;
        }

        .is-h2 {
            text-transform: uppercase;
            color: $color-white;
            margin-bottom: 67px;

            @include media-breakpoint-down(lg) {
                font-size: 32px;
                line-height: 32px;
                margin-bottom: $default-margin;
            }

            @include media-breakpoint-down(md) {
                font-size: 42px;
                line-height: 42px;
                margin-bottom: 67px;
            }
        }
    }
}

.cards-overview {
    @include make-row;
    margin: 0 -10px;

    &__item {
        @include make-col(6);
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}
