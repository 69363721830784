//scss-lint:disable PropertySpelling

form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        user-select: none;

        &.form-control {
            &:not([size], [multiple]) {
                height: auto;
            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    .form-message {
        padding: 10px 10px 0;
        color: $color-red;
        background: rgba($color-red, .1);
        border-left: 4px solid $color-red;
        margin: 0 0 10px;

        > * {
            margin-bottom: 10px;
        }

        a {
            color: $color-red;
            text-decoration: underline;
        }

        &--success {
            color: $color-green;
            background: rgba($color-green, .1);
            border-left: 4px solid $color-green;

            a {
                color: $color-green;
            }
        }
    }

    .form-group {
        margin: 0 0 $default-content-margin;

        > * {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }

        > label {
            font-weight: $font-weight-bold;
        }

        &.has-error {
            .form-control {
                border-color: $color-red;
            }

            .help-block {
                color: $color-red;
            }
        }

        .form-floating {
            > label {
                font-size: $font-size;
                line-height: $line-height;
                padding: 11px 15px 12px;
                margin: 0;
            }
        }

        &--in-treatment {
            + .form-group {
                display: none;
            }
        }
    }

    .form-floating,
    .form-control-wrapper {
        > * {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    .form-control {
        font-family: $text-font;
        font-size: $font-size;
        line-height: $line-height;
        height: auto;
        padding: 11px 15px 12px;
        border: 1px solid $color-white;
        background-color: $color-white;
        border-radius: 25px;
        border-color: $text-color;

        // Ios bug fix of zooming in when font size is less then 16px
        @supports (-webkit-overflow-scrolling: touch) {
            font-size: 16px;
        }

        &:focus {
            border-color: $text-color;
            background-color: $color-white;
            box-shadow: none;
        }

        &--textarea {
            height: 120px;
        }

        &--select {
            @include icon(select, $color-black, 14px, 14px);
            background-position: center right 10px;
            background-color: $color-white;
            padding-right: 40px;
            font-weight: $font-weight-bold;

            &:focus-visible {
                outline: 1px solid $color-burdau;
            }
        }

        &--check {
            position: relative;
            padding: 0 0 0 28px;
            margin: 0 0 10px;
            border: 0;
            border-radius: 0;
            background-color: transparent;

            label {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                opacity: 0;
                padding: 0;
                cursor: pointer;

                + i {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    display: block;
                    width: 18px;
                    background-color: $color-brown-light;
                    height: 18px;
                    border: 2px solid $color-white;

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: none;
                        width: 12px;
                        height: 12px;
                        content: '';
                        background-color: $text-color;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }

                &:focus-visible {
                    + i {
                        outline: 1px solid $text-color;
                    }
                }
            }

            input[type="radio"] {
                + i {
                    border-radius: 50%;

                    &::before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.form-horizontal {
    .form-group {
        @include make-row;
        margin-left: 0;
        margin-right: 0;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(md) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-flow: row wrap;
    align-items: baseline;
    margin-left: -10px;
    margin-right: -10px;

    .form-message {
        flex: 1 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .form-group {
        flex: 1;
        flex-direction: column;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 100%;
        }

        &:last-child {
            flex: 0;
        }

        &--error {
            flex: 0 0 100%;
            text-align: center;

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }

        .form-control,
        .help-block {
            width: 100%;
        }

        .btn {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        width: 100%;
        padding: 12px 55px 12px 15px;
        border-color: $color-border;
        font-size: $font-size;
        line-height: normal;
    }

    .btn {
        @include icon(search, $color-burdau, 20px, 20px);
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 60px;
        height: 100%;
        padding: 0;
        font-size: 0;
        border-color: transparent;
        background-color: transparent;
        border-radius: 0;

        &:hover,
        &:focus {
            @include icon(search, $color-blue-dark, 20px, 20px);
        }
    }
}

::input-placeholder {
    color: $text-color;
    font-style: normal;
}

::placeholder {
    color: $text-color;
    opacity: .7;
    font-style: normal;
}

:input-placeholder {
    color: $text-color;
    opacity: .7;
    font-style: normal;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}

.form-repeat {
    .medicin-item {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        &-hidden {
            display: none;
        }

        .form-group {
            flex: 1;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }
}

//scss-lint:enable PropertySpelling
