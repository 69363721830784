// Slider block

.block {
    &--slider {
        margin: 0 0 ($default-block-margin - $default-margin);

        .swiper {
            .swiper-navigation {
                margin: 0;
                display: block;

                button {
                    position: absolute;
                    top: 50%;
                    transform: rotate(180deg) translateY(-50%);
                    left: 28px;
                    z-index: 99;
                    transform-origin: top center;

                    @include media-breakpoint-down(xl) {
                        left: 10px;
                    }

                    @include media-breakpoint-down(md) {
                        top: initial;
                        left: initial;
                        bottom: 60px;
                        right: 40px;
                        transform: rotate(180deg);
                        transform-origin: center;
                    }

                    &.swiper-button-next {
                        left: initial;
                        right: 28px;
                        transform: rotate(0) translateY(-50%);

                        @include media-breakpoint-down(xl) {
                            left: initial;
                            right: 10px;
                        }

                        @include media-breakpoint-down(md) {
                            right: 5px;
                            transform: rotate(0);
                            transform-origin: center;
                        }
                    }
                }
            }
        }
    }
}

.slider-item {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 660px;
    margin: 0;

    &__image {
        position: relative;
        width: 100%;

        @include media-breakpoint-down(md) {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: calc(100vh - 125px);
            z-index: -1;
            min-height: 450px;
            max-height: 800px;
        }

        a {
            display: block;
        }

        picture {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__buttons {
        display: block;
        list-style: none;
        max-width: 37%;

        @include media-breakpoint-down(xl) {
            max-width: 65%;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        li {
            margin: 0 0 10px;

            @include media-breakpoint-down(xl) {
                @include make-col(6);
                padding: 0 10px;
            }

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 9px 20px 9px 30px;
                background: $color-burdau;
                border-radius: 2em;
                font-weight: $font-weight-bold;
                font-size: 16px;
                line-height: 22px;

                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                    content: '';
                    width: 6px;
                    height: 12px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    background: $color-blue-dark;
                }
            }
        }
    }

    &__wrapper {
        @extend .wrapper;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
            position: static;
            width: 100%;
            transform: none;
            height: calc(100vh - 125px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 450px;
            max-height: 800px;
        }
    }

    &__content {
        width: 65%;

        @include media-breakpoint-down(xl) {
            width: 100%;
        }

        .is-h2 {
            color: $color-white;
            text-transform: uppercase;
            margin-bottom: 50px;

            @include media-breakpoint-down(md) {
                font-size: 36px;
                line-height: 36px;
            }

            + .richtext {
                margin-top: -20px;
            }
        }

        .richtext {
            font-size: 22px;
            line-height: 30px;
            max-width: 70%;

            > * {
                color: $color-white;
            }
        }
    }

    &.decoration {
        &::before {
            z-index: 2;
        }

        #{ $self }__wrapper {
            z-index: 4;
        }
    }
}

.slider {
    $self: &;
    display: flex;
    margin: 0 0 $default-margin;

    &__item {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.slider-nav {
    display: none;

    &.swiper-pagination-bullets {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0 15px;
        z-index: 1;
        top: -70px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .swiper-pagination-bullet {
            flex: 0 0 10px;
            height: 10px;
            margin: 0 5px;
            opacity: .5;
            transition: all .3s ease-in-out;
            border: 0;
            border-radius: 50%;
            background: $text-color;

            &:hover,
            &:focus,
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}
