// Hero block

.block {
    &--hero {
        margin: 0 0 $default-block-margin;
    }
}

.hero {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 440px;

    @include media-breakpoint-down(md) {
        height: 640px;
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        overflow: hidden;

        picture {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__content {
        flex: 0 0 45%;
        width: 45%;
        max-width: 45%;
        padding: 0 60px;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            flex: 0 0 65%;
            width: 65%;
            max-width: 65%;
        }

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }

        .is-h2 {
            text-transform: uppercase;
            color: $color-white;
        }

        .richtext {
            font-size: 14px;
            line-height: 26px;
            font-weight: $font-weight-normal;
            color: $color-white;
        }
    }
}
