// scss-lint:disable ImportantRule
.block {
    $self: &;
    position: relative;
    margin-bottom: $default-block-margin - $default-margin;

    &:first-of-type {
        margin-top: -$default-block-margin;

        &:not(.block--slider, .block--image-block) {
            margin-top: 0;
        }
    }
}

// scss-lint:enable ImportantRule

.article {
    &--block {
        @include make-row;
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            .article {
                &--block {
                    &__image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }

        &__image {
            @include make-col(4);
            padding: 0 15px;
            margin: 0 0 30px;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }

            img {
                transition: transform .3s;
            }
        }

        &__content {
            @include make-col(8);
            padding: 0 15px;
            margin: 0 0 10px;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }
    }

    &--reverse {
        justify-content: flex-end;

        .article {
            &--block {
                &__image {
                    order: 2;

                    @include media-breakpoint-down(lg) {
                        order: 1;
                    }
                }

                &__content {
                    order: 1;

                    @include media-breakpoint-down(lg) {
                        order: 2;
                    }
                }
            }
        }
    }
}

// Gallery

.gallery {
    &--block {
        &__preview {
            position: relative;
            margin: 0 0 20px;

            a {
                display: block;
            }
        }
    }

    &--list {
        @include make-row;
        margin: 0 -10px 10px;

        &__item {
            @include make-col(4);
            padding: 0 10px;
            position: relative;
            margin: 0 0 20px;

            @include media-breakpoint-down(lg) {
                @include make-col(6);
            }

            a {
                display: block;
            }
        }
    }
}

// Search

.search-results {
    &--list {
        display: flex;
        flex-direction: column;

        &__item,
        &__empty-item {
            display: flex;
            flex-direction: column;
            margin: 0 0 $default-content-margin;
            border-bottom: 1px solid $color-border;
        }

        h2 {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 20px;
            }
        }

        .url {
            display: block;
            color: $color-burdau;
            margin-bottom: $default-content-margin;
            font-size: $font-size;
            line-height: $line-height;
            font-weight: $font-weight-bold;
        }
    }
}

.search-result {
    &--block {
        color: $text-color;
        text-decoration: none;
        margin: 0;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;
        }

        &__content {
            display: flex;
            flex-direction: column;
            font-weight: $font-weight-normal;
        }
    }
}
