// Latest News block

.block {
    &--latest-news {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.latest-news {
    @include make-row;
    margin: 0;

    @include media-breakpoint-down(md) {
        margin: 0 -10px;
    }

    &__title {
        @include make-col(4);
        padding: 0 20px;

        @include media-breakpoint-down(xl) {
            @include make-col(12);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
            flex-direction: column;
            padding: 0 10px;
        }

        .is-h2 {
            margin-bottom: 45px;
        }
    }

    &__content {
        @include make-col(8);
        padding: 0 20px;

        @include media-breakpoint-down(xl) {
            @include make-col(12);
        }

        @include media-breakpoint-down(md) {
            padding: 0 10px;
        }
    }
}

.latest-news-overview {
    @include make-row;
    margin: 0 -20px;

    @include media-breakpoint-down(md) {
        margin: 0 -10px;
    }

    &__item {
        @include make-col(6);
        padding: 0 20px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0 10px;
        }
    }
}
