// Treatments block

.block {
    &--treatments {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.treatments {
    display: flex;
    flex-direction: column;

    &__title {
        .is-h4 {
            margin: 0 0 15px;
        }
    }

    .link {
        &--purple {
            background-color: $color-purple;

            &:hover,
            &:focus {
                background-color: darken($color-purple, 10%);
            }
        }

        &--orange {
            background-color: $color-orange;

            &:hover,
            &:focus {
                background-color: darken($color-orange, 10%);
            }
        }

        &--brown {
            background-color: $color-brown;

            &:hover,
            &:focus {
                background-color: darken($color-brown, 10%);
            }
        }

        &--blue-light {
            background-color: $color-blue-light;

            &:hover,
            &:focus {
                background-color: darken($color-blue-light, 10%);
            }
        }

        &--blue-dark {
            background-color: $color-blue-dark;

            &:hover,
            &:focus {
                background-color: darken($color-blue-dark, 10%);
            }
        }

        &--blue {
            background-color: $color-blue;

            &:hover,
            &:focus {
                background-color: darken($color-blue, 10%);
            }
        }

        &--green {
            background-color: $color-green;

            &:hover,
            &:focus {
                background-color: darken($color-green, 10%);
            }
        }
    }
}
