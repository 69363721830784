.theme {
    background-color: $color-purple;

    p,
    blockquote,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    form,
    table,
    section,
    aside,
    article,
    dl,
    hr {
        color: $color-white;
    }

    h1,
    .is-h1,
    h2,
    .is-h2,
    h3,
    .is-h3,
    h4,
    .is-h4,
    h5,
    .is-h5,
    h6,
    .is-h6 {
        color: $color-white;
    }

    a,
    .link {
        color: $color-white;
    }

    .btn {
        &--link {
            color: $color-white;

            &::after {
                @include icon(arrow, $color-white, 6px, 12px);
            }

            &:hover,
            &:focus {
                color: $color-white;

                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                }
            }
        }
    }

    .tag {
        color: $color-purple;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-blue-dark;

        }
    }

    .list {
        &--meta {
            li {
                color: $color-white;
            }
        }
    }

    &--orange {
        background-color: $color-orange;

        .tag {
            color: $color-orange;

            &:hover,
            &:focus {
                color: $color-white;

            }
        }
    }

    &--brown {
        background-color: $color-brown;

        .tag {
            color: $color-brown;

            &:hover,
            &:focus {
                color: $color-white;

            }
        }
    }

    &--blue-light {
        background-color: $color-blue-light;

        .tag {
            color: $color-blue-light;

            &:hover,
            &:focus {
                color: $color-white;

            }
        }
    }

    &--blue-dark {
        background-color: $color-blue-dark;

        .tag {
            color: $color-blue-dark;

            &:hover,
            &:focus {
                color: $color-white;

            }
        }
    }

    &--blue {
        background-color: $color-blue;

        .tag {
            color: $color-blue;

            &:hover,
            &:focus {
                color: $color-white;

            }
        }
    }

    &--green {
        background-color: $color-burdau-light;

        .tag {
            color: $color-burdau-light;

            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }
}
