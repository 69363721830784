// Highlights block

.block {
    &--highlights {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.highlights {
    $self: &;
    display: flex;
    flex-direction: column;

    &__info {
        .info {
            font-size: 10px;
            text-align: center;
        }
    }
}

.highlight {
    $self: &;
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px $default-block-margin - ($default-margin + $default-content-margin);

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &__image {
        @include make-col(5);
        position: relative;
        padding: 0 10px;
        margin-bottom: $default-margin;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0 10px;
        }

        a {
            display: block;
        }

        img {
            border-radius: 6px;
            box-shadow: 0 0 15px rgba($color-black, .1);
        }
    }

    &__content {
        @include make-col-offset(1);
        @include make-col(5);
        position: relative;
        padding: 0 30px 0 10px;
        align-self: center;

        @include media-breakpoint-down(xl) {
            @include make-col(6);
        }

        @include media-breakpoint-down(lg) {
            @include make-col-offset(0);
            @include make-col(7);
            padding: 0 10px 0 30px;
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0 10px;
        }

        .btn:not(.btn--link) {
            min-width: 268px;
            max-width: 100%;
        }

        .is-h5 {
            + .heading {
                margin-top: -$default-margin;

                &--small {
                    margin-top: -($default-margin - 10px);
                }
            }
        }

        .richtext {
            + .read-more {
                margin-top: -$default-margin;
            }
        }
    }

    &--reversed {
        flex-direction: row-reverse;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        #{ $self }__image {
            @include make-col-offset(1);

            @include media-breakpoint-down(lg) {
                @include make-col-offset(0);
            }
        }

        #{ $self }__content {
            @include make-col-offset(0);
            padding: 0 10px 0 30px;

            @include media-breakpoint-down(lg) {
                padding: 0 30px 0 10px;
            }

            @include media-breakpoint-down(md) {
                padding: 0 10px;
            }
        }
    }

    &--news {
        position: relative;
        flex-direction: column;

        #{ $self }__image {
            @include make-col(12);
            @include make-col-offset(0);
            align-self: flex-start;
            padding: 0 10px;

            + #{ $self }__content {
                position: absolute;
                top: 0;
                left: 10px;
                right: 10px;
                padding: 20px 20px 0;

                .is-h5 {
                    color: $color-white;
                    font-size: 26px;
                    line-height: 36px;

                    a {
                        color: $color-white;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $color-blue-dark;
                        }
                    }
                }

                .list {
                    &--meta {
                        li {
                            color: $color-white;
                        }
                    }
                }

                .tag {
                    color: $text-color;
                    background-color: $color-white;

                    &:hover,
                    &:focus {
                        color: $color-white;
                        background-color: $color-blue-dark;
                    }
                }
            }
        }

        #{ $self }__content {
            @include make-col(12);
            @include make-col-offset(0);
            align-self: flex-start;
            padding: 0 10px;

            .is-h5 {
                + .list,
                + .richtext {
                    margin-top: -5px;
                }
            }

            .list {
                + .richtext {
                    margin-top: -($default-margin / 2);
                }
            }
        }
    }

    &--team {
        border-radius: 10px;
        box-shadow: 0 0 6px rgba($color-black, .16);
        min-height: 400px;

        #{ $self }__content {
            margin: 0;
            padding: 100px 20px 0;
            align-self: flex-start;
            width: 100%;

            .is-h2 {
                font-family: $custom-font;
                font-weight: $font-weight-normal;
                text-transform: uppercase;

                + .is-h6 {
                    margin-top: -30px;
                }
            }
        }
    }

    &--grid {
        height: 100%;

        #{ $self }__content {
            @include make-col(12);
            @include make-col-offset(0);
            align-self: flex-start;
            padding: 0;
            display: flex;
            flex-direction: column;
            height: 100%;

            .hidden {
                visibility: hidden;
                display: block;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .is-h4 {
                margin-bottom: 15px;
            }

            .richtext {
                + p {
                    margin-top: auto;
                }

                a:not(.btn) {
                    font-weight: $font-weight-normal;
                }
            }
        }
    }

    &--small {
        #{ $self }__content {
            @include make-col(12);
            @include make-col-offset(0);
            align-self: flex-start;
            padding: 0;
            margin-bottom: 0;

            .is-h5 {
                line-height: 26px;
            }

            .is-h3 {
                line-height: 42px;
                color: $color-burdau;
            }

            .richtext {
                margin-bottom: 10px;

                @include media-breakpoint-up(md) {
                    padding-right: 50px;
                }
            }
        }
    }

    &.theme {
        margin: 0 0 $default-margin;
    }
}

.highlights-overview {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &__item {
        @include make-col(12);
        padding: 0 10px;
        margin: 0 0 $default-margin;

        .highlight {
            height: 100%;
            margin: 0;
        }
    }

    &--two-columns {
        #{ $self }__item {
            @include make-col(12);
            padding: 0 10px;

            @include media-breakpoint-up(lg) {
                @include make-col(6);
            }
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
        }
    }

    &--four-columns {
        #{ $self }__item {
            @include make-col(12);

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }
        }
    }
}
