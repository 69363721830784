h1,
.is-h1 {
    color: $text-color;
    font-size: 52px;
    line-height: 52px;
    font-weight: 100;
    font-family: $heading-font;
    word-break: break-word;

    @include media-breakpoint-down(sm) {
        line-height: 42px;
        font-size: min(max(6vw, 32px), 46px);
    }
}

h2,
.is-h2 {
    color: $text-color;
    font-size: 42px;
    line-height: 42px;
    font-weight: 100;
    font-family: $heading-font;
    word-break: break-word;

    @include media-breakpoint-down(lg) {
        line-height: 32px;
        font-size: min(max(5vw, 28px), 34px);
    }
}

h3,
.is-h3 {
    color: $text-color;
    font-size: 32px;
    line-height: 32px;
    font-weight: 100;
    font-family: $heading-font;
}

h4,
.is-h4 {
    color: $text-color;
    font-size: 18px;
    line-height: 26px;
    font-weight: 100;
    font-family: $heading-font;
    margin: 0 0 $default-content-margin;
}

h5,
.is-h5 {
    color: $text-color;
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-bold;
    font-family: $heading-font;
    margin: 0 0 $default-content-margin;
}

h6,
.is-h6 {
    color: $color-black;
    font-size: 14px;
    line-height: 22px;
    font-weight: $font-weight-bold;
    font-family: $heading-font;
    margin: 0 0 $default-content-margin;
}

h1,
.is-h1,
h2,
.is-h2,
h3,
.is-h3 {
    + .info {
        font-size: 14px;
        font-style: normal;
        margin-top: -$default-margin;
    }
}

h4,
.is-h4,
h5,
.is-h5,
h6,
.is-h6 {
    + .info {
        font-size: 10px;
        font-style: normal;
        margin-top: -$default-content-margin;
    }
}
