// Content block

.block {
    &--content-block {
        margin: 0 0 $default-block-margin - $default-content-margin;
    }
}

.content-block {
    $self: &;

    @include make-col(8);
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        @include make-col(12);
    }

    li {
        list-style-image: url('/assets/img/favicon.png');
    }

    &__title {
        .is-h1 {
            + .is-h2 {
                margin-top: -($default-content-margin - 5px);
                font-weight: $font-weight-normal;
                line-height: 52px;

                @include media-breakpoint-down(md) {
                    line-height: 28px;
                }

                + .list {
                    margin-top: -($default-content-margin - 15px);
                }
            }

            + .list {
                margin-top: -($default-content-margin - 15px);
            }
        }

        .is-h2 {
            @include media-breakpoint-down(md) {
                font-size: 26px;
                line-height: 26px;
            }
        }

        .list {
            &--meta {
                li {
                    color: $text-color;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                }

                + .richtext {
                    margin-top: -($default-content-margin - 15px);
                }
            }
        }
    }

    &--two-columns {
        display: flex;
        flex-flow: row wrap;
        width: auto;
        margin: 0 -10px;

        #{ $self }__title {
            @include make-col(5);
            padding: 0 10px;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }

        #{ $self }__content {
            @include make-col(5);
            padding: 0 10px;
            margin: 0 auto;

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }
    }
}
