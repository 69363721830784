button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    $self: &;
    position: relative;
    display: inline-block;
    font-family: $heading-font;
    color: $color-white;
    font-size: 16px;
    line-height: 22px;
    padding: 19px 30px;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-align: center;
    background-color: $color-burdau;
    border-radius: 2em;

    @include media-breakpoint-down(lg) {
        padding: 19px 15px;
    }

    @include media-breakpoint-down(xs) {
        padding: 19px 30px;
    }

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        background-color: darken($color-burdau, 10%);
    }

    &--close {
        position: relative;
        background-color: $white;
        color: $text-color;
        padding: 11px 35px 10px 15px;
        border-radius: 20px;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        min-width: 100px;

        &::after {
            @include icon(close, $text-color, 8px, 8px);
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;
            background-color: $color-burdau;

            &::after {
                @include icon(close, $color-white, 8px, 8px);
            }
        }
    }

    &--big {
        font-size: 22px;
        line-height: 30px;
        padding: 12px 20px;
    }

    &--white {
        color: $color-burdau;
        border-color: $color-white;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $color-burdau;
            border-color: darken($color-white, 10%);
            background-color: darken($color-white, 10%);
        }
    }

    &--transparent {
        color: $color-burdau;
        border: 1px solid $color-burdau;
        background-color: transparent;

        &:hover,
        &:focus {
            color: darken($color-burdau, 10%);
            border-color: darken($color-burdau, 10%);
            background-color: transparent;
        }

        &#{ $self }--white {
            color: $color-white;
            border-color: $color-white;

            &:hover,
            &:focus {
                color: darken($color-white, 10%);
                border-color: darken($color-white, 10%);
            }
        }
    }

    &--video {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 14px;
        line-height: 26px;
        text-transform: uppercase;
        border: 1px solid $text-color;
        background-color: $color-blue-dark;
        border-radius: 20vh;
        padding: 16px 30px;
        align-self: baseline;

        &::before {
            @include icon(play, $color-white, 20px, 20px);
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background-size: contain;
            margin-right: 10px;
        }

        &:hover,
        &:focus {
            border-color: $color-orange;
            background-color: $color-orange;

            &::before {
                @include icon(play, $color-white, 20px, 20px);
            }
        }
    }

    &--link {
        color: $color-burdau;
        font-size: $font-size;
        line-height: $line-height;
        padding: 0 20px 0 0;
        border: 0;
        background-color: transparent;
        position: relative;

        &::after {
            @include icon(arrow, $color-burdau, 6px, 12px);
            content: '';
            position: absolute;
            width: 6px;
            height: 12px;
            top: 7px;
            right: 0;
            transition: right .3s ease-in-out;
        }

        &:hover,
        &:focus {
            color: $text-color;
            background-color: transparent;

            &::after {
                @include icon(arrow, $text-color, 6px, 12px);
                right: -5px;
            }
        }

        &#{ $self }--blue-dark {
            background: transparent;
            color: $color-blue-dark;

            &::after {
                @include icon(arrow, $color-blue-dark, 6px, 12px);
            }

            &:hover,
            &:focus {
                color: $color-burdau;
                background: transparent;

                &::after {
                    @include icon(arrow, $color-burdau, 6px, 12px);
                }
            }
        }
    }

    &--social-share {
        color: $color-white;
        font-size: 14px;
        line-height: normal;
        font-weight: $font-weight-bold;
        padding: 5px 0 5px 28px;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &:hover,
        &:focus {
            color: $color-orange;
            text-decoration: none;
            background-color: transparent;

            &::after {
                @include icon(share, $color-orange, 16px, 20px);
            }
        }

        &::after {
            @include icon(share, $color-white, 16px, 20px);
            position: absolute;
            top: 5px;
            left: 0;
            display: block;
            width: 16px;
            height: 20px;
            content: '';
        }
    }

    &--purple {
        background-color: $color-purple;

        &:hover,
        &:focus {
            background-color: darken($color-purple, 10%);
        }
    }

    &--orange {
        background-color: $color-orange;

        &:hover,
        &:focus {
            background-color: darken($color-orange, 10%);
        }
    }

    &--brown {
        background-color: $color-brown;

        &:hover,
        &:focus {
            background-color: darken($color-brown, 10%);
        }
    }

    &--blue-light {
        background-color: $color-blue-light;

        &:hover,
        &:focus {
            background-color: darken($color-blue-light, 10%);
        }
    }

    &--blue-dark {
        background-color: $color-blue-dark;

        &:hover,
        &:focus {
            background-color: darken($color-blue-dark, 10%);
        }
    }

    &--blue {
        background-color: $color-blue;

        &:hover,
        &:focus {
            background-color: darken($color-blue, 10%);
        }
    }

    &--green {
        background-color: $color-burdau-light;

        &:hover,
        &:focus {
            background-color: darken($color-burdau-light, 10%);
        }
    }
}

#scrollTopBtn {
    background: #a91f2f;
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 20px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    &::after {
        @include icon(arrow, $color-white, 20px, 20px);
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        transform: rotate(-90deg);
    }
}

