// Bootstrap settings
$container-max-widths: (
    'sm': 540px,
    'md': 720px,
    'lg': 960px,
    'xl': 1170px
) !default;
$gutters: (
    'sm': 30px,
    'md': 30px,
    'lg': 30px,
    'xl': 30px
) !default;
$form-switch-bg-image-dark: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><style>.st0 {fill: #fff;}</style><path class="st0" d="M17.6,5.4c0.7-0.5,1.5-0.5,2,0.2l0,0c0.5,0.7,0.5,1.5-0.2,2l-8.3,7.1c-0.5,0.5-1.3,0.5-1.8,0l-8.8-7c-0.7-0.5-0.7-1.5-0.2-2c0.5-0.7,1.3-0.8,2-0.3l0,0l7.8,6.3L17.6,5.4z"/></svg>';
$form-select-indicator-dark: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><style>.st0 {fill: #fff;}</style><path class="st0" d="M17.6,5.4c0.7-0.5,1.5-0.5,2,0.2l0,0c0.5,0.7,0.5,1.5-0.2,2l-8.3,7.1c-0.5,0.5-1.3,0.5-1.8,0l-8.8-7c-0.7-0.5-0.7-1.5-0.2-2c0.5-0.7,1.3-0.8,2-0.3l0,0l7.8,6.3L17.6,5.4z"/></svg>';

// Lightcase settings
$lightcase-custom: (
    'font-path': '/assets/fonts/',
    'breakpoint': 768px
) !default;

// Overall
$default-margin: 40px;
$default-block-margin: 60px;
$default-content-margin: 25px;
$text-font: 'Open Sans', sans-serif;
$heading-font: 'Open Sans', sans-serif;
$custom-font: 'Playfair Display', serif;
$font-size: 16px;
$line-height: 26px;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$text-color: #17253f;
$color-purple: #6f3a60;
$color-burdau: #a91f2f;
$color-orange: #df9f47;
$color-blue: #345c66;
$color-blue-dark: #17253f;
$color-blue-light: #859da3;
$color-brown: #c1aa6e;
$color-orange-light: #fde8bf;
$color-brown-light: #efe9de;
$color-burdau-light: #986e70;
$color-green: #86914d;
$color-gray: #97999d;
$color-border: #d5ccbd;
$color-white: #fff;
$color-pink: #ed1164;
$color-black: #000;
$color-black-off: #333;
$color-black-light: #666;
$color-gray-1: #f5f7f8;
$color-gray-2: #eaeaea;
$color-gray-3: #f7f7f7;
$color-red: #db001b;
$color-yellow: #ffea50;
$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
