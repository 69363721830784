.breadcrumb {
    display: block;
    padding: 15px;
    margin: -$default-block-margin 0 $default-margin;
    background-color: transparent;
    border-radius: 0;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &-list {
        display: flex;
        padding: 0;
        margin: 0;
        font-size: 12px;
    }

    &-item {
        color: $text-color;
        text-transform: lowercase;

        a {
            color: $text-color;

            &:hover,
            &:focus {
                color: $color-blue;
                text-decoration: none;
            }
        }

        &.active {
            a {
                color: $text-color;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                padding-left: 5px;

                &::before {
                    content: '>';
                    font-size: 12px;
                    color: $text-color;
                    padding-right: 5px;
                }
            }
        }
    }
}
