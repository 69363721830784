// Quote block

.block {
    &--quote {
        margin-bottom: $default-block-margin;
    }
}

.quote {
    min-height: 440px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    padding: 20px;

    &__content {
        @include make-col(6);
        padding: $default-content-margin 3rem 0;

        @include media-breakpoint-down(lg) {
            @include make-col(10);
            padding: $default-content-margin 0 0;
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .is-h2 {
            text-transform: uppercase;
            text-align: center;
        }

        .richtext {
            p {
                font-style: italic;
            }

            + p {
                text-align: center;

                .btn {
                    min-width: 55%;
                }
            }
        }
    }
}
