// Overview block

.block {
    &--overview {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.overview {
    $self: &;
    display: flex;
    flex-direction: column;

    &__info {
        .info {
            font-size: 10px;
            text-align: center;
        }
    }

    .overview {
        &__title {
            @include make-col(4);
            padding-left: 15px;
            padding-right: 25px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }
        }

        &__content {
            @include make-col(8);
            padding-left: 25px;
            padding-right: 15px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }
        }
    }

    &__vacancies {
        flex-direction: row;

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }

        @include media-breakpoint-between(lg, xl) {
            flex-direction: row;
        }

        .overview {
            &__title {
                @include media-breakpoint-down(md) {
                    @include make-col(4);
                }

                @include media-breakpoint-down(lg) {
                    @include make-col(12);
                }
            }

            &__content {
                @include media-breakpoint-down(md) {
                    @include make-col(8);
                }

                @include media-breakpoint-down(lg) {
                    @include make-col(12);
                    padding-left: 5px;
                }
            }
        }

        * {
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
        }

        a {
            cursor: pointer;

            &:hover,
            &:focus {
                .vacancy {
                    text-decoration: none;
                    background-color: $color-burdau;

                    * {
                        color: $color-white;
                    }

                    .vacancy-detail-item {
                        &__education {
                            &::before {
                                @include icon(graduation, $color-white, 18px, 18px);
                            }
                        }

                        &__hours {
                            &::before {
                                @include icon(clock, $color-white, 15px, 15px);
                            }
                        }

                        &__location {
                            &::before {
                                @include icon(locationdot, $color-white, 15px, 15px);
                            }
                        }

                        &__closedate {
                            &::before {
                                @include icon(calendardate, $color-white, 15px, 15px);
                            }
                        }
                    }
                }
            }

            .vacancy {
                padding: 15px;

                &__bottom {
                    display: flex;
                    position: absolute;
                    bottom: 15px;
                    left: 10px;
                }

                &__read-more {
                    margin-left: auto;

                    a {
                        margin: 0;
                    }
                }

                &__specifications {
                    display: flex;

                    @include media-breakpoint-down(xl) {
                        flex-direction: column;
                    }

                    .vacancy-detail-item {
                        &__education {
                            margin-left: 25px;
                            font-size: 13px;
                            position: relative;
                            padding-left: 5px;

                            &::before {
                                content: '';
                                height: 20px;
                                width: 20px;
                                left: -20px;
                                top: 3px;

                                @include icon(graduation, $color-burdau, 18px, 18px);
                                position: absolute;
                            }
                        }

                        &__hours {
                            margin-left: 25px;
                            font-size: 13px;
                            position: relative;
                            padding-left: 5px;

                            &::before {
                                content: '';
                                height: 20px;
                                width: 20px;
                                left: -20px;
                                top: 2px;

                                @include icon(clock, $color-burdau, 15px, 15px);
                                position: absolute;
                            }
                        }

                        &__location {
                            margin-left: 25px;
                            font-size: 13px;
                            position: relative;
                            padding-left: 5px;

                            &::before {
                                content: '';
                                height: 20px;
                                width: 20px;
                                left: -20px;
                                top: 3px;

                                @include icon(locationdot, $color-burdau, 15px, 15px);
                                position: absolute;
                            }
                        }

                        &__closedate {
                            margin-left: 25px;
                            font-size: 13px;
                            position: relative;
                            padding-left: 5px;

                            &::before {
                                content: '';
                                height: 20px;
                                width: 20px;
                                left: -20px;
                                top: 3px;

                                @include icon(calendardate, $color-burdau, 15px, 15px);
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }

        .filter {
            display: block;

            .overview {
                &__title {
                    background-color: $color-white;
                }
            }
        }
    }
}

.waiting-times-overview {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-border;
    margin: 0 0 $default-margin;

    &__item {
        padding: 20px 0 0;
        border-top: 1px solid $color-border;
    }
}

.vacancies-overview {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-border;
    margin: 0 0 $default-margin;

    &__content {
        @include make-col(8);
        padding-left: 15px;
        padding-right: 15px;
    }

    &__filter {
        @include make-col(4);
        padding-left: 15px;
        padding-right: 15px;

        .vacancy-filter {
            display: block;
            background-color: $color-white;
        }
    }

    &__item {
        padding: 20px 0 0;

        &:nth-of-type(1) {
            padding: 0;
        }
    }
}

.news-overview {
    @include make-row;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    margin: 0 0 $default-margin;

    &__item {
        @include make-col(4);
        padding: 20px 0 0;
        margin: 0 0 -1px;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        &:nth-child(-n+3) {
            margin-top: -1px;
        }

        .highlight {
            margin-right: 50px;

            @include media-breakpoint-down(md) {
                margin-right: 30px;
            }

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
    }
}

.waiting-time {
    @include make-row;
    margin: 0 -10px;

    &__title {
        @include make-col(3);
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(3);
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(4);
        }

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &__title,
    &__content {
        > * {
            margin: 0 0 20px;
        }

        .is-h5,
        .is-h6 {
            + p {
                margin-top: -$default-content-margin;
            }
        }
    }
}

.vacancy {
    background-color: $color-white;
    flex-flow: row wrap;
    margin: 0 -10px;
    padding: 10px;
    height: 220px;
    position: relative;

    &__title {
        flex: 1;

        @include media-breakpoint-down(lg) {
            flex: 0 0 100%;
        }

        h1, h2, h3, h4, h5 {
            color: $color-burdau;
            font-size: 22px;
        }

        a {
            color: $color-burdau;
            font-size: 22px;
        }
    }

    p {
        font-weight: normal;

        @include media-breakpoint-down(xl) {
            display: none;
        }
    }

    &__content {
        flex: 0 0 190px;
        max-width: 190px;
        padding: 0 10px;

        @include media-breakpoint-down(xl) {
            flex: 0 0 auto;
            max-width: none;
        }
    }

    &__read-more {
        padding: 0 10px;

        @include media-breakpoint-down(xl) {
            flex: 0 0 100%;
        }
    }

    &__title,
    &__content,
    &__read-more {
        > * {
            margin: 0 0 20px;
        }
    }
}

.filter {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -15px;

    &__title {
        flex: 1;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }
    }

    &__filter {
        display: flex;
        align-items: flex-end;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        .form {
            display: flex;
            flex-flow: row wrap;
            margin: 0 -20px ($default-margin - $default-content-margin);

            .form-group {
                flex: 1;
                padding: 0 20px;
            }

            .form-control {
                width: 250px;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }

    &__vacancy-filter {
        align-items: flex-end;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        .form {
            display: flex;
            flex-flow: column wrap;
            margin: 0 -20px ($default-margin - $default-content-margin);
            background-color: $color-white;
            padding-bottom: 15px;
            border-radius: 10px;

            .show-results {
                margin: 15px;
                margin-bottom: 0;
                border-radius: 10px;
            }

            .reset-filters {
                padding-left: 15px;
                padding-top: 10px;
                text-decoration: underline;
                font-size: 13px;
                margin-bottom: 5px;
            }

            .filter-group {
                flex: 1;

                .filter-header {
                    width: 100%;
                    background-color: $color-white;

                    .filter-btn {
                        font-size: 18px;
                        font-weight: 100;
                        display: block;
                        padding-left: 15px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        cursor: pointer;
                        border-top: $color-burdau 1px solid;

                        &:hover {
                            color: unset;
                            text-decoration: none;
                        }
                    }
                }

                .filter-list {
                    display: none;
                    overflow: hidden;
                    transition: max-height 0.1s ease-out;
                    background-color: darken($color-brown-light, 8%);

                    &.active {
                        display: block;
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }

                    .filter-item {
                        display: flex;
                        padding-left: 15px;

                        label {
                            margin-left: 13px;
                            font-size: 14px;
                        }
                    }
                }
            }

            .form-control {
                width: 250px;
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}

.job-vacancy-benefits {
    background-color: $color-burdau;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;

    @include media-breakpoint-down(sm) {
        text-align: left;
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    span {
        color: $color-white;

    }

    &__education {
        margin-left: 35px;
        font-size: 13px;
        position: relative;
        padding-left: 5px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            left: -20px;
            top: 2px;

            @include icon(graduation, $color-white, 18px, 18px);
            position: absolute;
        }
    }

    &__hours {
        margin-left: 35px;
        font-size: 13px;
        position: relative;
        padding-left: 5px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            left: -20px;
            top: 2px;

            @include icon(clock, $color-white, 15px, 15px);
            position: absolute;
        }
    }

    &__location {
        margin-left: 35px;
        font-size: 13px;
        position: relative;
        padding-left: 5px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            left: -20px;
            top: -2px;

            @include icon(locationdot, $color-white, 15px, 15px);
            position: absolute;
        }
    }

    &__closedate {
        margin-left: 35px;
        font-size: 13px;
        position: relative;
        padding-left: 5px;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            left: -20px;
            top: 2px;

            @include icon(calendardate, $color-white, 15px, 15px);
            position: absolute;
        }
    }
}