@import '../../../../../../node_modules/swiper/swiper';

.swiper {
    margin: 0;
    overflow: hidden;

    .swiper-slide {
        height: auto;
    }

    .swiper-pagination-bullets {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 0 $default-margin;

        .swiper-pagination-bullet {
            display: flex;
            flex: 0 0 16px;
            height: 16px;
            border: 2px solid $text-color;
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            margin: 0 4px;
            padding: 0;
            overflow: hidden;
            outline: none;

            &:hover,
            &:active {
                outline: none;
            }

            &.swiper-pagination-bullet-active {
                background-color: $text-color;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.swiper-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 0 $default-margin;

    .swiper-button-next,
    .swiper-button-prev {
        @include icon(arrow, $text-color, 20px, 20px);
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin: 0;

        &.swiper-button-disabled {
            cursor: default;
            opacity: 0;
        }

        &[disabled] {
            cursor: default;
            opacity: 0;
        }
    }

    .swiper-button-prev {
        transform: rotate(180deg);
    }

    .swiper-button-next {
        transform: rotate(0);
    }
}

.swiper-fade {
    &.swiper-free-mode {
        .swiper-slide {
            transition-timing-function: ease-out;
        }
    }

    .swiper-slide {
        pointer-events: none;
        transition-property: opacity;

        .swiper-slide {
            pointer-events: none;
        }
    }

    .swiper-slide-active {
        &,
        & .swiper-slide-active {
            pointer-events: auto;
        }
    }
}
