// Products block

.block {
    &--products {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.products {
    $self: &;
    display: flex;
    flex-direction: column;

    &__title {
        .is-h2 {
            + .richtext {
                margin: -($default-margin / 2) 0 ($default-margin / 2);
            }
        }
    }

    &__content {
        + #{ $self }__buttons {
            margin-top: -($default-margin / 2);
        }
    }

    &__buttons {
        display: flex;
        min-height: 60px;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        .swiper-navigation {
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0 0 ($default-margin / 2) auto;
        }

        p {
            + .swiper-navigation {
                bottom: 40px;
            }
        }
    }

    .richtext {
        a {
            color: $color-white;
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

.product {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 6px rgba($color-black, .16);
    min-height: 140px;

    &__image {
        margin: 0;

        picture {
            position: relative;
            overflow: hidden;

            img {
                transform: scale(1);
                transition: transform .3s ease-in-out;
            }
        }

        + #{ $self }__content {
            margin-top: 10px;
        }

        + #{ $self }__title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 20px;
        }
    }

    &__title {
        bottom: 0;
        position: relative;
        transition: bottom .3s ease-in-out;

        .is-h4 {
            color: $color-white;
        }
    }

    &__content {
        padding: 20px 40px 10px 20px;
        top: 0;
        position: relative;
        transition: top .3s ease-in-out;
        margin-top: auto;

        .is-h4 {
            margin-bottom: 15px;

            + .read-more {
                margin-top: -10px;
            }
        }

        .read-more {
            margin-bottom: 10px;
        }
    }

    &.decoration {
        #{ $self }__image {
            position: relative;
        }
    }

    &[href] {
        cursor: pointer;
    }

    &[href]:hover,
    &[href]:focus {
        text-decoration: none;

        .btn {
            &--link {
                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                    right: -5px;
                }
            }
        }

        #{ $self }__image {
            picture {
                img {
                    transform: scale(1.1);
                }
            }

            + #{ $self }__content {
                top: -5px;
            }

            + #{ $self }__title {
                bottom: 5px;
            }
        }
    }
}

.products-slider {
    $self: &;
    display: flex;
    flex-direction: row;
    margin: 0 0 $default-margin;

    &__item {
        @include make-col(4);
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(md) {
            @include make-col(9.5);
        }

        .product,
        .highlight {
            height: 100%;
        }
    }

    &--basic {
        flex-wrap: wrap;
        margin: 0 -15px $default-margin;

        #{ $self }__item {
            margin-bottom: $default-margin / 2;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }
}

.swiper {
    &--products-slider {
        overflow: initial;
        margin: 0 -10px;

        .swiper-slide {
            height: auto;
        }
    }
}

.swiper-navigation {
    &--products-slider {
        text-align: right;
        padding: 0 40px;

        @include media-breakpoint-down(md) {
            padding: 0 15px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            @include icon(arrow, $color-burdau, 11px, 6px);
            background-size: contain;
            transform: rotate(0) scale(1);
            transition: transform .3s ease-in-out;
            width: 30px;
            height: 30px;

            &.swiper-button-disabled {
                cursor: default;
                opacity: 1;
                transform: scale(.7);
            }

            &[disabled] {
                opacity: 0;
            }

            &:not(.swiper-button-disabled):hover,
            &:not(.swiper-button-disabled):focus {
                @include icon(arrow, $color-blue-dark, 11px, 6px);
            }
        }

        .swiper-button-prev {
            transform: rotate(180deg) scale(1);

            &.swiper-button-disabled {
                transform: rotate(180deg) scale(.7);
            }
        }

        .swiper-button-next {
            transform: rotate(0) scale(1);

            &.swiper-button-disabled {
                transform: rotate(0) scale(.7);
            }
        }
    }
}
