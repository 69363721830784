.block {
    &--article {
        margin: 0 0 $default-block-margin;
    }
}

.article {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &__title {
        @include make-col(12);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto $default-block-margin;
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
            flex-flow: column nowrap;
            align-items: flex-start;
            margin: 0 0 20px;
        }

        .is-h3 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
            line-height: 55px;
        }

        .is-h1 {
            display: block;
            margin-bottom: 10px;
        }

        .info-publish {
            display: block;
            text-align: center;
            margin-top: -10px;
            font-size: 14px;
            line-height: 28px;
        }

        .form {
            display: flex;
            flex-flow: row wrap;
            flex: 0 0 auto;
            flex-grow: 1;
            margin: 0 0 0 15%;

            @include media-breakpoint-down(lg) {
                margin: 20px 0 $default-content-margin;
            }

            .form-group {
                flex-grow: 1;
                margin-bottom: 0;

                + .form-group {
                    margin-left: 40px;
                }
            }

            .form-control {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }

    &__content {
        @include make-col(6.5);
        display: flex;
        flex-direction: column;
        margin: 0 0 10px;
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
        }
    }

    &__form {
        @include make-col(4.5);
        margin: 0 0 10px auto;
        padding: 0 10px;
        align-self: flex-end;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .form {
            margin-bottom: 0;
        }
    }

    &--search {
        justify-content: center;

        #{ $self }__title {
            @include make-col(8);

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }

        #{ $self }__content {
            @include make-col(8);

            @include media-breakpoint-down(lg) {
                @include make-col(12);
            }
        }
    }
}
