// Image block

.block {
    &--image-block {
        margin: 0 0 $default-block-margin;
    }
}

.image-block {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 340px;
    padding: $default-margin 0 0;
    z-index: 0;

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        picture {
            height: 100%;
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__title {
        @extend .wrapper;

        .is-h2 {
            color: $color-white;
            max-width: 100%;
            text-shadow: 0 0 6px rgba($color-black, .2);
            text-transform: uppercase;

            @include media-breakpoint-down(lg) {
                line-height: normal;
                font-size: min(max(5vw, 16px), 22px);
            }
        }

        p {
            max-width: 50%;

            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }
    }

    &--static {
        @include media-breakpoint-down(lg) {
            min-height: unset;
            padding-top: 0;
        }

        #{ $self }__image {
            @include media-breakpoint-down(lg) {
                position: static;
                transform: none;
            }

            img {
                @include media-breakpoint-down(xs) {
                    min-height: 130px;
                }
            }
        }

        #{ $self }__title {
            @include media-breakpoint-down(lg) {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
    }
}
