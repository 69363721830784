.header {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    margin: 0 0 $default-block-margin;
    transition: all .3s ease-in-out;
    z-index: 999;
    background-color: $color-white;
    box-shadow: 0 1px 3px rgba($color-black, .1);

    &__search {
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        margin: -75px 0 0;
        background-color: #fff;
        transition: all .3s ease-in-out;
        z-index: 4;

        .form-search {
            @extend .wrapper;
            margin: 0 auto;
            position: relative;

            .form-control {
                border: 1px solid $color-border;
            }

            .btn {
                right: 20px;
                width: 60px;
            }
        }

        .search-suggestions {
            position: absolute;
            display: none;
            top: 100%;
            left: 20px;
            right: 20px;
            border-radius: 10px;
            z-index: 8;
            background-color: $color-blue-dark;
            border: 1px solid $color-blue-dark;
            margin: 5px 0 0;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 16px;
                height: 16px;
                content: '';
                transform: rotate(45deg);
                border-top: 1px solid $color-blue-dark;
                border-left: 1px solid $color-blue-dark;
                background-color: $color-blue-dark;
                margin: -8px 0 0 -8px;
            }

            &.active {
                display: block;
            }

            li {
                display: block;
                border-bottom: 1px solid $color-white;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    padding: 7px 15px;
                    font-size: $font-size;
                    color: $color-white;
                    line-height: $line-height;
                    transition: padding .3s ease-in-out;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        padding-left: 25px;
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 32px 28px;

        @include media-breakpoint-down(lg) {
            height: 60px;
            padding: 5px 13px;
        }
    }

    .logo {
        display: block;
        height: 35px;
        order: 2;
        margin: 0;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            height: 29px;
            margin: 0 auto;

        }

        img {
            display: inherit;
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }

    .navigation {
        &--header {
            z-index: 0;
            order: 3;
        }
    }

    .cta {
        display: block;
        width: 40px;
        height: 40px;
        padding: 7px;
        margin: 0;
        font-size: 0;
        transition: all .3s ease-in-out;
        z-index: 1;

        &--nav {
            @include icon(menu, $color-burdau, 20px, 20px);
            display: none;
            order: 1;

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 50%;
                left: 15px;
                display: block;
                margin: -20px 0 0;
            }

            &:hover,
            &:focus {
                @include icon(menu, $text-color, 20px, 20px);
            }

            &:focus-visible {
                outline: 1px solid $color-burdau;
            }

            &.active {
                @include icon(close, $color-burdau, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(close, $text-color, 20px, 20px);
                }
            }
        }

        &--search {
            @include icon(search, $color-burdau, 20px, 20px);
            margin: 0 0 0 10px;
            order: 4;

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 50%;
                right: 65px;
                display: block;
                margin: -20px 0 0;
            }

            &:hover,
            &:focus {
                @include icon(search, $text-color, 20px, 20px);
            }

            &:focus-visible {
                outline: 1px solid $color-burdau;
            }

            &.active {
                @include icon(close, $color-burdau, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(close, $text-color, 20px, 20px);
                }

                &:focus-visible {
                    outline: 1px solid $color-burdau;
                }
            }
        }

        &--account {
            position: relative;
            width: auto;
            color: $color-burdau;
            font-size: 12px;
            line-height: 20px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding: 10px 7px 10px 40px;
            margin: 0 0 0 10px;
            order: 5;

            @include media-breakpoint-down(lg) {
                position: absolute;
                top: 50%;
                right: 15px;
                display: block;
                width: 40px;
                font-size: 0;
                padding: 7px;
                margin: -20px 0 0;
            }

            &::after {
                @include icon(account, $color-burdau, 20px, 20px);
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 40px;
                height: 40px;
                content: '';
            }

            &:hover,
            &:focus {
                color: $text-color;
                text-decoration: none;

                &::after {
                    @include icon(account, $text-color, 20px, 20px);
                }
            }

            &:focus-visible {
                outline: 1px solid $color-burdau;
            }
        }
    }
}


.primary-search-active {
    .header {
        margin: 0 0 ($default-block-margin - 75px);

        &__search {
            margin: 0;
        }
    }
}
