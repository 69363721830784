// Pagination block

.block {
    &--pagination {
        display: flex;
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.pagination {
    margin: 0 0 $default-margin;
}
