.footer {
    position: relative;
    flex-shrink: 0;
    background: $color-blue-dark;

    @include media-breakpoint-down(lg) {
        margin: 0 0 68px;
    }

    @media print {
        display: none;
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        line-height: 24px;
        padding: 15px 25px 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright {
        display: inline-block;
        margin: 0 0 10px;
        color: $color-white;
        font-size: 10px;
        line-height: 26px;
        font-weight: $font-weight-normal;
    }

    .navigation {
        &--footer {
            display: inline-block;
            margin: 0 0 10px;
        }
    }

    .social-share {
        margin: 0 auto 15px;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

#caggb-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

#caggb-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 500px;
    text-align: center;
}

.popup-content {
    h2 {
        margin-top: 0;
    }
}
