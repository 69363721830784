.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;

        a {
            &:not(.btn, .tag) {
                display: block;
                color: $text-color;
            }
        }
    }

    &--light {
        margin: 0 0 $default-content-margin;

        li {
            a {
                color: $color-gray;
                padding: 6px 0;
                font-weight: $font-weight-normal;
                font-size: 17px;
                line-height: normal;

                &:not(.btn, .tag) {
                    color: $color-gray;
                }

                &:hover,
                &:focus {
                    color: $color-blue-dark;
                    text-decoration: none;

                }
            }

            &.active {
                a {
                    font-weight: $font-weight-bold;

                    &:not(.btn, .tag) {
                        color: $color-blue-dark;
                    }
                }
            }
        }
    }

    &--locations {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px ($default-margin - 10px);

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            margin: 0 0 20px;
            text-align: left;

            @include media-breakpoint-down(lg) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 9px 20px 9px 30px;
                background: $color-orange;
                border-radius: 2em;
                font-weight: $font-weight-bold;
                font-size: 16px;
                line-height: 22px;

                &:not(.btn, .tag) {
                    color: $color-white;
                }

                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                    content: '';
                    width: 6px;
                    height: 12px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    background: $color-burdau;
                }
            }
        }
    }

    &--buttons {
        display: block;
        list-style: none;
        max-width: 37%;

        @include media-breakpoint-down(xl) {
            max-width: 65%;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        li {
            margin: 0 0 10px;

            @include media-breakpoint-down(xl) {
                @include make-col(6);
                padding: 0 10px;
            }

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            .btn {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 9px 20px 9px 30px;
                border-radius: 2em;
                font-weight: $font-weight-bold;
                font-size: 16px;
                line-height: 22px;
                text-align: left;

                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                    content: '';
                    width: 6px;
                    height: 12px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    &--meta {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px $default-margin;
        align-items: center;

        li {
            font-size: 10px;
            color: rgba($text-color, .5);
            padding: 0 10px;
        }
    }

    &--treatments {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px ($default-margin - 20px);

        li {
            padding: 0 10px;
            margin: 0 0 20px;
            font-size: 14px;
            line-height: 26px;

            a {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 7px 35px 7px 25px;
                background: $color-orange;
                border-radius: 2em;
                font-weight: $font-weight-bold;

                &:not(.btn, .tag) {
                    color: $color-white;
                }

                &::after {
                    @include icon(arrow, $color-white, 6px, 12px);
                    content: '';
                    width: 6px;
                    height: 12px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    background: $color-burdau;
                }
            }
        }
    }

    &--social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0 0 8px;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $color-white;
                font-size: 14px;
                line-height: 22px;
                padding: 5px 15px 5px 36px;

                &:not(.btn, .tag) {
                    color: $color-white;
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $color-white, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $color-white, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $color-white, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $color-white, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $color-white, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $color-white, 20px, 20px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $color-white, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $color-white, 20px, 20px);
                    }
                }
            }
        }
    }
}
