.decoration {
    $self: &;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        width: 766px;
        height: 848px;
        background-image: url('../../img/decoration.svg');
        background-repeat: no-repeat;
        background-position: left top;
        position: absolute;
        left: -10%;
        top: 5%;
        opacity: .4;
        z-index: 0;

        @include media-breakpoint-down(md) {
            background-size: 60%;
        }
    }

    &--right {
        &::before {
            left: inherit;
            right: -10%;
        }
    }

    &--two {
        &::before {
            left: -20%;
            top: -100%;
        }

        &#{ $self }--right {
            &::before {
                left: inherit;
                right: -20%;
            }
        }
    }

    &--three {
        &::before {
            left: 2%;
            top: 0;
        }

        &#{ $self }--right {
            &::before {
                left: inherit;
                right: -2%;
            }
        }
    }

    &.product {
        &::before {
            left: -115%;
            top: -45%;
        }

        &#{ $self }--two {
            &::before {
                left: -80%;
                top: -90%;
            }
        }

        &#{ $self }--three {
            &::before {
                left: 0;
                top: -120%;
            }
        }
    }

    &.slider-item {
        &::before {
            top: 50%;
            left: -226px;
            width: 764px;
            height: 846px;
            margin: -423px 0 0;
        }

        &#{ $self }--right {
            &::before {
                left: inherit;
                right: -226px;
            }
        }
    }

    &.hero {
        &::before {
            left: -25%;
            top: -50%;
            z-index: 1;
        }

        &#{ $self }--two {
            &::before {
                left: -80%;
                top: -90%;
            }
        }

        &#{ $self }--three {
            &::before {
                left: 0;
                top: -120%;
            }
        }

        &#{ $self }--right {
            &::before {
                left: inherit;
                right: -25%;
            }

            &#{ $self }--two {
                left: 0;
                right: -80%;
            }

            &#{ $self }--three {
                left: 0;
                right: 0;
            }
        }
    }

    &.card {
        &::before {
            left: -25%;
            top: -23%;
        }

        &#{ $self }--two {
            &::before {
                left: -80%;
                top: -90%;
            }
        }

        &#{ $self }--three {
            &::before {
                left: -10%;
                top: -60%;
                background-size: contain;
                height: 225%;
                width: 170%;
            }
        }
    }

    &.highlight {
        &::before {
            left: -115%;
            top: -45%;
        }

        &#{ $self }--two {
            &::before {
                left: -80%;
                top: -90%;
            }
        }

        &#{ $self }--three {
            &::before {
                left: 0;
                top: -120%;
            }
        }
    }

    &.quote {
        &::before {
            left: -33%;
            top: -25%;

            @include media-breakpoint-down(xl) {
                background-size: 75%;
            }

            @include media-breakpoint-down(lg) {
                background-size: 60%;
            }

            @include media-breakpoint-down(md) {
                background-size: 50%;
                top: 40%;
            }
        }

        &::after {
            content: '';
            width: 766px;
            height: 848px;
            background-image: url('../../img/decoration.svg');
            background-repeat: no-repeat;
            background-position: left top;
            position: absolute;
            right: -26%;
            bottom: 22%;
            opacity: .4;
            z-index: 0;

            @include media-breakpoint-down(xl) {
                background-size: 75%;
                right: -40%;
                bottom: -30%;
            }

            @include media-breakpoint-down(lg) {
                background-size: 60%;
                right: -80%;
                bottom: -50%;
            }

            @include media-breakpoint-down(md) {
                right: -100%;
                bottom: -50%;
            }
        }
    }
}
