.pagination {
    margin: 0 0 $default-block-margin;
    border-radius: 0;

    a {
        &:hover {
            background-color: $color-burdau;
            text-decoration: none;
        }

        &:focus-visible {
            background-color: $color-burdau;
            outline: 2px solid $color-burdau;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin: 0;

        .page-item {
            display: inline-block;
            margin: 0 5px;

            &:first-child,
            &:last-child {
                .page-link {
                    border-radius: 50%;
                }
            }

            &.active {
                .page-link {
                    background-color: $color-burdau;
                }
            }

            &.control {
                @include media-breakpoint-down(lg) {
                    display: none;
                }

                .page-link {
                    @include icon(arrow, $color-border, 18px, 12px);
                    background-color: transparent;
                    text-indent: -99999px;

                    &:hover,
                    &:focus {
                        @include icon(arrow, $color-blue-dark, 18px, 12px);
                    }
                }

                &.prev {
                    .page-link {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .page-link {
            display: block;
            width: 40px;
            height: 40px;
            padding: 7px 0;
            color: $color-white;
            font-size: 16px;
            font-weight: $font-weight-bold;
            line-height: 26px;
            text-align: center;
            overflow: hidden;
            border-radius: 50%;
            background-color: $color-blue-dark;
            border: 0;
        }
    }
}
