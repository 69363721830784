.tag {
    display: inline-block;
    color: $color-white;
    font-size: 10px;
    line-height: 20px;
    font-weight: $font-weight-bold;
    padding: 0 10px;
    border-radius: 10px;
    text-decoration: none;
    background-color: $color-burdau;

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        background-color: darken($color-burdau, 10%);
    }

    &--big {
        font-size: 14px;
        padding: 10px 16px;
        border-radius: 20px;
    }

    &--purple {
        background-color: $color-purple;

        &:hover,
        &:focus {
            background-color: darken($color-purple, 10%);
        }
    }

    &--orange {
        background-color: $color-orange;

        &:hover,
        &:focus {
            background-color: darken($color-orange, 10%);
        }
    }

    &--brown {
        background-color: $color-brown;

        &:hover,
        &:focus {
            background-color: darken($color-brown, 10%);
        }
    }

    &--blue-light {
        background-color: $color-blue-light;

        &:hover,
        &:focus {
            background-color: darken($color-blue-light, 10%);
        }
    }

    &--blue-dark {
        background-color: $color-blue-dark;

        &:hover,
        &:focus {
            background-color: darken($color-blue-dark, 10%);
        }
    }

    &--blue {
        background-color: $color-blue;

        &:hover,
        &:focus {
            background-color: darken($color-blue, 10%);
        }
    }

    &--green {
        background-color: $color-green;

        &:hover,
        &:focus {
            background-color: darken($color-green, 10%);
        }
    }
}
